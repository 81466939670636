<template>
  <div class="policy_of_points">
    <van-nav-bar title="积分政策" left-arrow @click-left="$router.go(-1)" />
    <div class="content" v-html="val"></div>
  </div>
</template>

<script>
export default {
  name: "policy-of-points",

  data() {
    return {
      val: "",
    };
  },

  created() {
    sessionStorage.getItem("policy")
      ? (this.val = sessionStorage.getItem("policy"))
      : this.$router.replace("/");
  },
  destroyed() {
    sessionStorage.removeItem("policy");
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.policy_of_points {
  // background: #fff;
  .content {
    padding: 20px 15px;
  }
}
</style>